<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Clientes</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Administrar Pagos de Clientes</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4><strong>ADMINISTRAR PAGOS DE CLIENTES</strong></h4>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-2">
            <label for="sucursal"><strong>SUCURSAL:</strong></label>
            <Dropdown
              v-model="sucursalSelected"
              :options="sucursales"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-4">
            <label for="proveedor_id"><strong>CLIENTE:</strong></label>
            <Dropdown
              v-model="clienteSelected"
              :options="clientes"
              optionLabel="nombre"
              optionValue="id"
              :filter="true"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-3">
            <label for=""><strong>TIPO DE PAGO: </strong></label>
            <Dropdown
              v-model="tipoPagoSelected"
              :options="tipo_pagos"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-3">
            <label for=""><strong>M&Eacute;TODO DE PAGO: </strong></label>
            <Dropdown
              v-model="metodoPagoSelected"
              :options="metodo_pagos"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-3">
            <label for=""><strong>FECHA INICIO: </strong></label>
            <InputText
              v-model="fecha_inicio"
              placeholder="Fecha Inicio"
              type="date"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for=""><strong>FECHA FIN: </strong></label>
            <InputText
              v-model="fecha_fin"
              placeholder="Fecha Fin"
              type="date"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="fecha_vencimiento_hasta"
              ><strong>ACCIONES: </strong></label
            >
            <Button
              label="APLICAR FILTROS"
              icon="pi pi-search"
              class="p-button-primary mr-2"
              v-tooltip.top="'Aplicar los filtros'"
              :loading="enviando"
              :disabled="enviando"
              @click="BuscarFiltro"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="pago_clientes"
        key="id"
        :value="pago_clientes"
        responsiveLayout="scroll"
        :loading="enviando"
        class="p-datatable-sm white-space-nowrap"
        :filters="buscar"
        responsive="true"
        stripedRows
        showGridlines
        :rows="50"
        :paginator="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[50, 100, 500, 1000, 5000, 10000]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Pagos de Clientes"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar Pagos de CLientes..."
              />
            </span>
          </div>
        </template>
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="p-invalid">
            Debe aplicar filtros para ver resultados en la tabla!</span
          >
        </template>
        <Column field="venta_id" header="NRO.VENTA" class="text-right"></Column>
        <Column field="cliente_nombre" header="CLIENTE"></Column>
        <!-- <Column field="tipo_pago_nombre" header="TIPO PAGO">
          <template #body="{ data }">
            <div>
              {{ data.tipo_pago_nombre }}
            </div>
          </template>
        </Column> -->
        <!-- <Column field="metodo_pago_nombre" header="MÉTODO PAGO">
          <template #body="{ data }">
            <div>
              {{ data.metodo_pago_nombre }}
            </div>
          </template>
        </Column> -->
        <Column
          field="fecha_pago"
          header="FECHA VENTA"
          :sortable="true"
        ></Column>
        <Column class="text-right" field="total_venta" header="TOTAL VENTA">
          <template #body="{ data }">
            <div>
              {{ convertirNumeroGermanicFormat(data.total_venta) }}
            </div>
          </template>
        </Column>
        <!-- <Column field="dias_credito" header="DÍAS CRÉDITO">
          <template #body="{ data }">
            <div v-if="data.dias_credito == 0">
              <span>CONTADO</span>
            </div>
            <div v-else>
              <span>{{ data.dias_credito }}</span>
            </div>
          </template>
        </Column> -->
        <Column class="text-right" field="a_cuenta_efectivo" header="EFECTIVO">
          <template #body="{ data }">
            <div>
              {{ convertirNumeroGermanicFormat(data.a_cuenta_efectivo) }}
            </div>
          </template>
        </Column>
        <Column class="text-right" field="a_cuenta_metodo_pago" header="TRANSFERENCIAS">
          <template #body="{ data }">
            <div>
              {{ convertirNumeroGermanicFormat(data.a_cuenta_metodo_pago) }}
            </div>
          </template>
        </Column>
        <Column class="text-right" field="saldo" header="SALDO">
          <template #body="{ data }">
            <div>
              {{ convertirNumeroGermanicFormat(data.saldo) }}
            </div>
          </template>
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado]">
              {{ estadoTexto(data.estado) }}
            </span>
          </template>
        </Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              class="p-button-icon-only p-button-raised"
              icon="pi pi-ellipsis-v"
              v-tooltip.top="'Ver Acciones'"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            />
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            >
            </Menu>
          </template>
        </Column>
      </DataTable>
      <PagoCliente
        :show="pagoClienteDialog"
        :pago_cliente="{ ...pago_cliente }"
        @closeModal="cerrarModalPagoCliente"
        @actualizarListado="actualizarListado"
      />
    </div>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api";
import ClienteService from "@/service/ClienteService";
import SucursalService from "@/service/SucursalService";
import PagoCliente from "@/module/clientes/PagoCliente.vue";
import TipoPagoService from "@/service/TipoPagoService";
import MetodoPagoService from "@/service/MetodoPagoService";
import PagoClienteService from "@/service/PagoClienteService";
import VentaService from "@/service/VentaService";

export default {
  components: {
    PagoCliente,
  },
  data() {
    return {
      pago_clientes: [],
      pago_cliente: {},
      sucursales: [{ id: 0, nombre: "TODAS" }],
      sucursalSelected: 0,
      clientes: [{ id: 0, nombre: "TODOS" }],
      clienteSelected: 0,
      tipo_pagos: [{ id: 0, nombre: "TODOS" }],
      tipoPagoSelected: 0,
      metodo_pagos: [{ id: 0, nombre: "TODOS" }],
      metodoPagoSelected: 0,
      fecha_inicio: null,
      fecha_fin: null,
      enviando: false,
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      pagoClienteDialog: false,
      claseEstado: ["","status-instock", "status-outofstock", "status-instock"],
    };
  },
  clienteService: null,
  sucursalService: null,
  tipoPagoService: null,
  metodoPagoService: null,
  pagoClienteService: null,
  ventaService: null,
  created() {
    this.clienteService = new ClienteService();
    this.sucursalService = new SucursalService();
    this.tipoPagoService = new TipoPagoService();
    this.metodoPagoService = new MetodoPagoService();
    this.pagoClienteService = new PagoClienteService();
    this.ventaService = new VentaService();
  },
  mounted() {
    this.cargarSucursales();
    this.cargarClientes();
    this.cargarTipoPagos();
    this.cargarMetodoPagos();
  },
  methods: {
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE").format(numero);
    },
    acciones(datos) {
      return [
        {
          label: "Registrar Pago",
          disabled: datos.estado == 1 ? true : false,
          /* visible: "Pago Proveedor Crear" in this.auth.user.permissions ? true : false, */
          icon: "pi pi-plus",
          command: () => {
            this.NuevoPagoCliente(datos);
          },
        },

        {
          label: "Ver Detalle de Pago",
          icon: "pi pi-eye",
          to: "pago_clientes/" + datos.id + "/detalle",
        },
        {
          label: "Imprimir Venta",
          icon: "pi pi-print",
          command: () => {
            this.imprimirVenta(datos.id);
          },
        },
      ];
    },
    imprimirVenta(id) {
      this.ventaService
        .imprimirVenta(id)
        .then(() => {})
        .catch((error) => console.log(error));
    },
    actualizarListado() {
      this.BuscarFiltro();
    },
    NuevoPagoCliente(datos) {
      this.pago_cliente = { ...datos };
      this.pagoClienteDialog = true;
    },
    cerrarModalPagoCliente() {
      this.pagoClienteDialog = false;
    },
    BuscarFiltro() {
      this.pago_clientes = [];
      this.enviando = true;
      this.cargarPagoClientes();
    },
    cargarPagoClientes() {
      let datos = {
        sucursal_id: this.sucursalSelected == 0 ? null : this.sucursalSelected,
        cliente_id: this.clienteSelected,
        tipo_pago_id: this.tipoPagoSelected,
        metodo_pago_id: this.metodoPagoSelected,
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_fin,
      }
      this.pagoClienteService
        .getAllPagoClientes(datos)
        .then((response) => {
          this.pago_clientes = response.pago_clientes;
          this.enviando = false;
        })
        .catch(() => {
          this.enviando = false;
        });
    },
    cargarTipoPagos() {
      this.tipoPagoService
        .getAllTipoPagos()
        .then((response) => {
         this.tipo_pagos = response.tipo_pagos;
        this.tipo_pagos.push({ id: 0, nombre: "TODOS" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarMetodoPagos() {
      this.metodoPagoService
        .getAllMetodoPagos()
        .then((response) => {
          this.metodo_pagos = response.metodo_pagos;
          this.metodo_pagos.push({ id: 0, nombre: "TODOS" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((response) => {
          response.forEach((element) => {
            this.sucursales.push(element);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarClientes() {
      this.clienteService.getClientesAll()
        .then((response) => {
          response.forEach((element) => {
            this.clientes.push(element);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    estadoTexto (estado) {
      let texto = "";
      switch (estado) {
        case 1:
          texto = "PAGADO";
          break;
        case 2:
          texto = "PENDIENTE";
          break;
      }
      return texto;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-fondo {
  background-color: #7bffae;
}
</style>
